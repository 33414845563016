<template>
  <b-card
    no-body
  >
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="5"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div class="">
            <h3>Total de productos</h3> <small> (Hay {{ totalProducts }} productos)</small>
          </div>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="7"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Buscar por nombre, código"
            />
            <v-select
              v-model="filterQueryCategory"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="productsCategoriesList"
              label="name"
              class="w-100 mr-1"
              :reduce="val => val.id"
              placeholder="Filtrar por categoria..."
            />
            <v-select
              v-model="filterQueryStatus"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="productStatusOptions"
              class="w-100 mr-1"
              :reduce="val => val.value"
              placeholder="Filtrar por estado..."
            />
            <b-button
              variant="primary"
              @click="$router.push({ name: 'product-create'})"
            >
              Crear
            </b-button>
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="refProductsListTable"
      :items="fetchProducts"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      empty-text="No se encontraron registros coincidentes"
      class="position-relative"
    >
      <!-- Column: Id -->

      <!-- Column: Product status -->
      <template #cell(status_name)="data">
        <b-badge
          pill
          :variant="`light-${resolveStatusVariant(data.item.status_name)}`"
          class="text-capitalize"
        >
          {{ data.item.status_name }}
        </b-badge>
      </template>
      <!-- Column: Product status -->
      <template #cell(name)="data">
        <b-link
          :to="{ name: 'product-edit', params: { id: data.item.id }}"
          class="alert-link font-weight-bold d-block text-nowrap"
        >
          {{ data.item.name }}
        </b-link>
        <p>{{ data.item.category }}</p>
      </template>

      <!-- Column: Product Price PEN -->
      <template #cell(price_pen)="data">
        <span>{{ data.item.price_pen }}</span> <br>
        <span v-if="data.item.discount">({{ data.item.discount }}%)</span> <br>
        <span v-if="data.item.discount">{{ (data.item.price_pen - (data.item.price_pen* data.item.discount/100)).toFixed(2) }}</span>
      </template>

      <!-- Column: Product Price USD -->
      <template #cell(price_usd)="data">
        <span>{{ data.item.price_usd }}</span> <br>
        <span v-if="data.item.discount">({{ data.item.discount }}%)</span> <br>
        <span v-if="data.item.discount">{{ (data.item.price_usd - (data.item.price_usd* data.item.discount/100)).toFixed(2) }}</span>
      </template>

      <!-- Column:Stock -->
      <template #cell(stock)="data">
        <b-avatar
          :id="`invoice-row-${data.item.id}`"
          size="32"
          :variant="`light-${resolveStockVariant(data.item.stock)}`"
        >
          {{ data.item.stock }}
        </b-avatar>
      </template>

      <!-- Column: Photo -->
      <template #cell(photo)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-img
              v-if="data.item.photo"
              rounded
              thumbnail
              fluid
              :src="data.item.photo"
              width="200"
              alt="Photo dramox"
            />
          </template>
        </b-media>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <feather-icon
            :id="`product-row-${data.item.id}-history-icon`"
            icon="BookIcon"
            class="cursor-pointer mr-1"
            size="16"
            @click="$router.push({ name: 'product-history-stock', params: { code: data.item.product_code }})"
          />
          <b-tooltip
            title="Historial de stock"
            class="cursor-pointer"
            :target="`product-row-${data.item.id}-history-icon`"
          />

          <feather-icon
            :id="`product-row-${data.item.id}-edit-icon`"
            icon="Edit2Icon"
            size="16"
            class="cursor-pointer mr-1"
            @click="$router.push({ name: 'product-edit', params: { id: data.item.id }})"
          />
          <b-tooltip
            title="Editar"
            :target="`product-row-${data.item.id}-edit-icon`"
          />

          <span v-can-permissions="'Agregar stock productos'">
            <feather-icon
              :id="`product-row-${data.item.id}-add-icon`"
              icon="PlusIcon"
              size="16"
              class="mr-1 cursor-pointer"
              @click="clearInputStock"
            />
            <b-tooltip
              title="Agregar stock"
              :target="`product-row-${data.item.id}-add-icon`"
            />
          </span>

          <b-popover
            :target="`product-row-${data.item.id}-add-icon`"
            variant="primary"
            triggers="focus"
            placement="left"
          >
            <template #title>
              <span>Agregar stock</span>
            </template>

            <b-form-group
              label-for="popover-input-1"
              :state="inputStockState"
              class="mb-1"
              invalid-feedback="Este campo es requerido"
            >
              <b-form-input
                id="popover-input-1"
                ref="input1"
                v-model.number="stockValue"
                type="number"
                :state="inputStockState"
                size="sm"
              />
            </b-form-group>
            <b-button
              variant="primary"
              class="mr-1"
              size="sm"
              @click="saveStock(data.item)"
            >
              <span class="text-nowrap">Guardar</span>
            </b-button>
          </b-popover>

          <span
            v-if="data.item.status !== 0"
            v-can-permissions="'Eliminar productos'"
          >
            <feather-icon
              :id="`product-row-${data.item.id}-delete-icon`"
              icon="TrashIcon"
              size="16"
              class="cursor-pointer mr-1"
              @click="deleteProduct(data.item)"
            />
            <b-tooltip
              title="Eliminar"
              :target="`product-row-${data.item.id}-delete-icon`"
            />
          </span>

          <span v-can-permissions="'Clonar producto'">
            <feather-icon
              :id="`product-row-${data.item.id}-clone-icon`"
              icon="CopyIcon"
              size="16"
              class="cursor-pointer"
              @click="clearInputBusinessType"
            />
            <b-tooltip
              title="Clonar producto"
              :target="`product-row-${data.item.id}-clone-icon`"
            />

            <b-popover
              :target="`product-row-${data.item.id}-clone-icon`"
              variant="primary"
              triggers="focus"
              placement="left"
            >
              <template #title>
                <span>Clonar producto</span>
              </template>

              <b-form-group
                label-for="popover-input-1"
                :state="inputBusinessTypeState"
                class="mb-1"
                invalid-feedback="Este campo es requerido"
              >
                <b-form-select
                  v-model="cloneInBusinessType"
                  :state="inputBusinessTypeState"
                  :options="businessTypeOptions"
                />
              </b-form-group>
              <b-button
                variant="primary"
                class="mr-1"
                size="sm"
                @click="cloneProduct(data.item)"
              >
                <span class="text-nowrap">Clonar</span>
              </b-button>
            </b-popover>
          </span>

        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-end justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalProducts"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BAvatar, BBadge,
  BButton,
  BCard,
  BCol,
  BFormInput,
  BMedia, BPagination,
  BRow,
  BTable, BTooltip, BImg, BPopover, BFormGroup, BLink, BFormSelect,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import productStoreModule from '@/views/dramox/product/productStoreModule'
import productHandler from '@/views/dramox/product/productHandler'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import router from '@/router'

export default {
  name: 'HomeBusiness',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    // BButton,
    BTable,
    BMedia,
    BAvatar,
    BPagination,
    BTooltip,
    BBadge,
    BButton,
    vSelect,
    BImg,
    BPopover,
    BFormGroup,
    BLink,
    BFormSelect,
  },
  data() {
    return {
    }
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-products'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, productStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
    const toast = useToast()

    const showNotification = ({ title, icon, variant }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }
    const inputStockState = ref(null)
    const inputBusinessTypeState = ref(null)
    const stockValue = ref(null)
    const cloneInBusinessType = ref(null)

    const productStatusOptions = [
      { label: 'TODOS', value: '' },
      { label: 'DESACTIVADO', value: '0' },
      { label: 'PUBLICO', value: '1' },
      { label: 'PRIVADO', value: '2' },
    ]
    const businessTypeOptions = [
      { value: 'companies', text: 'Empresas' },
      { value: 'distributors', text: 'Distribuidores' },
    ]

    const {
      tableColumns,
      currentPage,
      totalProducts,
      productsCategoriesList,
      perPage,

      searchQuery,
      filterQueryStatus,
      filterQueryCategory,

      refProductsListTable,
      refetchData,
      fetchProducts,

      resolveStatusVariant,
      resolveStockVariant,
    } = productHandler()

    const clearInputStock = () => {
      stockValue.value = null
      inputStockState.value = null
    }

    const clearInputBusinessType = () => {
      cloneInBusinessType.value = null
      inputBusinessTypeState.value = null
    }

    const saveStock = item => {
      if (!stockValue.value) {
        inputStockState.value = false
        return
      }

      store.dispatch('app-products/updateProductStock', {
        body: {
          productId: item.id,
          stock: stockValue.value,
        },
      })
        .then(() => {
          showNotification({ title: 'Stock agregado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
          refetchData()
        })
        .catch(error => {
          const messageError = error.response.data.error.errors[0]
          showNotification({ title: messageError, icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    const deleteProduct = val => {
      store.dispatch('app-products/deleteProduct', { id: val.id })
        .then(() => {
          showNotification({ title: 'Eliminado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
          refetchData()
        })
        .catch(error => {
          const messageError = error.response.data.error.errors[0]
          showNotification({ title: messageError, icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    const cloneProduct = val => {
      if (!cloneInBusinessType.value) {
        inputBusinessTypeState.value = false
        return
      }
      store.dispatch('app-products/cloneProduct', { id: val.id, body: { businessType: cloneInBusinessType.value } })
        .then(() => {
          showNotification({ title: 'Producto clonado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
          // refetchData()
        })
        .catch(error => {
          const messageError = error.response.data.error.errors[0]
          showNotification({ title: messageError, icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }
    return {
      // businessObject,
      // resetBusinessData,
      fetchProducts,
      productsCategoriesList,

      tableColumns,
      currentPage,
      totalProducts,

      searchQuery,
      filterQueryStatus,
      filterQueryCategory,

      perPage,
      refProductsListTable,
      refetchData,
      avatarText,
      resolveStatusVariant,
      resolveStockVariant,
      productStatusOptions,
      // businessData,
      deleteProduct,

      inputStockState,
      stockValue,
      clearInputStock,
      saveStock,

      inputBusinessTypeState,
      businessTypeOptions,
      cloneInBusinessType,
      cloneProduct,
      clearInputBusinessType,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
